import {ItemGateway} from "../../corelogic/gateways/ItemGateway";
import {AuthenticationGateway} from "../../corelogic/gateways/AuthenticationGateway";
import {OrderGateway} from "../../corelogic/gateways/OrderGateway";
import {OrderLineGateway} from "../../corelogic/gateways/OrderLineGateway";
import {UserItemFavoritesGateway} from "../../corelogic/gateways/UserItemFavoritesGateway";
import {CustomerGateway} from "../../corelogic/gateways/CustomerGateway";
import {UserGateway} from "../../corelogic/gateways/UserGateway";
import {CustomerAddressesGateway} from "../../corelogic/gateways/CustomerAddressesGateway";
import {ItemPackagingGateway} from "../../corelogic/gateways/ItemPackagingGateway";
import {CustomerItemReferenceGateway} from "../../corelogic/gateways/CustomerItemReferenceGateway";
import {SocietyGateway} from "../../corelogic/gateways/SocietyGateway";
import {ParameterGateway} from "../../corelogic/gateways/ParameterGateway";
import {StatsGateway} from "../../corelogic/gateways/StatsGateway";
import {ToolsGateway} from "../../corelogic/gateways/ToolsGateway";
import {ServerGateway} from "../../corelogic/gateways/ServerGateway";
import {InvoiceGateway} from "../../corelogic/gateways/InvoiceGateway";
import {InvoiceLineGateway} from "../../corelogic/gateways/InvoiceLineGateway";

export interface IGateways {
    authenticationGateway: AuthenticationGateway | null
    itemGateway: ItemGateway | null
    orderGateway: OrderGateway | null
    orderLineGateway: OrderLineGateway | null
    userItemFavoritesGateway: UserItemFavoritesGateway | null
    customerGateway: CustomerGateway | null
    customerAddressesGateway: CustomerAddressesGateway | null
    userGateway: UserGateway | null
    itemPackagingGateway: ItemPackagingGateway | null
    customerItemReferenceGateway: CustomerItemReferenceGateway | null
    societyGateway: SocietyGateway | null
    parameterGateway: ParameterGateway | null
    statsGateway: StatsGateway | null
    toolsGateway: ToolsGateway | null
    serverGateway: ServerGateway | null
    invoiceGateway: InvoiceGateway | null
    invoiceLineGateway: InvoiceLineGateway | null
}

export interface GatewayInitialize {
    getAllGateways(): IGateways
}

export enum MODES_ACTION {
    CREATE,
    READ,
    UPDATE,
}