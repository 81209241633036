import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../i18n/messages";
import {DividingHR} from "./DividingHR";
import {MonetaryField, TextValueFieldBillingBox} from "./TextValueField";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerSpecificData} from "../../../../store/selectors/serverSelector";
import {CustomerSpecificCode, CustomerSpecificData} from "../../../../corelogic/models/CustomerSpecificData";
import {Customer} from "../../../../corelogic/models/customer";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import {getUserLoggedSelector} from "../../../../store/selectors/sessionSelector";
import {recalculateShippingCost, updateShippingCost} from "../../../../corelogic/usecase/orders/ordersActions";
import {Controller, SubmitHandler, useForm} from "react-hook-form";

interface OrderTotalProps {
    totalGrossTaxExcluded?: number
    totalShippingCost?: number
    shippingCostForced?: boolean
    discount?: number
    totalTaxExcluded?: number
    totalVat?: number
    totalWithTax?: number
    vatExcluded?: boolean
    customer?: Customer | null
    editMode?: boolean
    orderId: number
}

type InputsFormOrderTotal = {
    totalShippingCost: number
}

const OrderTotal = (props: OrderTotalProps) => {
    const {
        totalGrossTaxExcluded,
        totalShippingCost,
        shippingCostForced,
        discount,
        totalTaxExcluded,
        totalVat,
        totalWithTax,
        vatExcluded,
        customer,
        editMode = false,
        orderId
    } = props
    const intl = useIntl()
    const dispatch = useDispatch()
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const {isCustomer} = useSelector(getUserLoggedSelector)
    const [displayEditDialog, setDisplayEditDialog] = useState(false)
    const inputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue("totalShippingCost", totalShippingCost || 0)
    }, [totalShippingCost])

    const {
        handleSubmit,
        control,
        setValue
    } = useForm<InputsFormOrderTotal>(
        {
            mode: 'onSubmit',
        }
    )
    const handleClickOnTotalShippingCostDialog = () => {
        setDisplayEditDialog(true)
        inputRef?.current?.focus()
    }
    const handleCloseDialogTotalShippingCostEdit = () => {
        setValue("totalShippingCost", totalShippingCost || 0)
        setDisplayEditDialog(false)
    }
    const handleValidateTotalShippingCostUpdate: SubmitHandler<InputsFormOrderTotal> = (data) => {
        dispatch<any>(updateShippingCost(orderId, data.totalShippingCost))
        setDisplayEditDialog(false)
    }
    const handleRecalculateShippingCost = () => {
        dispatch<any>(recalculateShippingCost(orderId))
        setDisplayEditDialog(false)
    }

    return <>
        <Grid container direction="column" py={1} px={5}>
            <Grid item>
                <Typography fontWeight="bolder" textAlign="center">
                    <FormattedMessage id={messages.orderTotalTitle.id}/>
                </Typography>
                {customerSpecificData?.customerSpecificCode === CustomerSpecificCode.XAVIER &&
                    <XavierSpeComponent customerSpecificData={customerSpecificData}
                                        customerCategoryCode={customer?.category?.code}/>}
            </Grid>
            <DividingHR/>
            <Grid container item>
                <Grid item xs={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={(!editMode || isCustomer) ? 12 : 11}>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalGrossExcludingTaxes"))}
                                value={totalGrossTaxExcluded || 0}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid xs={(!editMode || isCustomer) ? 12 : 11}>
                                    <TextValueFieldBillingBox
                                        style={{
                                            marginBottom: "0px",
                                            fontWeight: "bolder",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor(shippingCostForced && !isCustomer ? "orderTotalShippingCostsForced" : "orderTotalShippingCosts"))}
                                        value={totalShippingCost || 0}/>
                                </Grid>
                                {(editMode && !isCustomer) && <Grid xs={1}>
                                    <IconButton color="primary" size="small"
                                                onClick={handleClickOnTotalShippingCostDialog}>
                                        <EditIcon fontSize="inherit"/>
                                    </IconButton>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={(!editMode || isCustomer) ? 12 : 11}>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalDiscount"))}
                                value={discount || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={2} alignItems="center"
                      justifyContent={"center"}>
                    <DividingHR
                        subContent
                        style={{height: "60px", width: 1, margin: 0}}/>
                </Grid>
                <Grid item xs={5}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalExcludingTaxes"))}
                                value={totalTaxExcluded || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={`${intl.formatMessage(getMessageDescriptor("orderTotalVat"))} ${vatExcluded ? "(Exonéré)" : ""}`}
                                value={totalVat || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalIncludingTaxes"))}
                                value={totalWithTax || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {displayEditDialog && <Dialog
            open={true}
            onClose={handleCloseDialogTotalShippingCostEdit}
            disableRestoreFocus
            component="form"
            onSubmit={handleSubmit(handleValidateTotalShippingCostUpdate)}
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage id={messages.orderUpdateShippingCostTotal.id}/>
            </DialogTitle>
            <DialogContent>
                <Controller
                    name="totalShippingCost"
                    control={control}
                    render={({field}) => <TextField
                        {...field}
                        autoFocus
                        inputRef={input => input && input.select()}
                        fullWidth
                        variant="standard"
                        label={intl.formatMessage(getMessageDescriptor("customerPageFormAmountDue"))}
                        InputProps={{
                            inputComponent: MonetaryField as any,
                        }}
                    />
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="warning"
                        onClick={handleRecalculateShippingCost}>
                    Recalcul
                    {/*<FormattedMessage id={messages.genericCancel.id}/>*/}
                </Button>
                <Button variant="outlined" color="error"
                        onClick={handleCloseDialogTotalShippingCostEdit}><FormattedMessage
                    id={messages.genericCancel.id}/></Button>
                <Button variant="outlined" type="submit"><FormattedMessage
                    id={messages.genericConfirm.id}/></Button>
            </DialogActions>
        </Dialog>}
    </>
}

interface XavierSpeComponentProps {
    customerSpecificData: CustomerSpecificData
    customerCategoryCode?: string
}

const XavierSpeComponent = (props: XavierSpeComponentProps) => {
    const {customerSpecificData, customerCategoryCode} = props
    const excludedCategories = customerSpecificData.top4 ? customerSpecificData.top4.split(',').map(c => c.trim()) : []
    const intl = useIntl()
    return (customerCategoryCode && !excludedCategories.includes(customerCategoryCode)) ? <>
            {!Number.isNaN(Number(customerSpecificData?.top1)) && <Typography variant="body2" gutterBottom>
                <FormattedMessage id={messages.specificXavierTop1.id}
                                  values={{
                                      top1: intl.formatNumber(Number(customerSpecificData?.top1), {
                                          style: 'percent',
                                          maximumFractionDigits: 2
                                      })
                                  }}
                />
            </Typography>}
            {(!Number.isNaN(Number(customerSpecificData?.top2)) && !Number.isNaN(Number(customerSpecificData?.top3))) &&
                <Typography variant="body2" gutterBottom>
                    <FormattedMessage id={messages.specificXavierTop2AndTop3.id}
                                      values={{
                                          top1: intl.formatNumber(Number(customerSpecificData?.top1), {
                                              style: 'percent',
                                              maximumFractionDigits: 2
                                          }),
                                          top2: intl.formatNumber(Number(customerSpecificData?.top2), {
                                              style: 'percent',
                                              maximumFractionDigits: 2
                                          }),
                                          top3: intl.formatNumber(Number(customerSpecificData?.top3), {
                                              style: 'decimal',
                                              maximumFractionDigits: 0
                                          })
                                      }}
                    />
                </Typography>}
        </>
        : <></>
}
export default OrderTotal