import {InvoiceDetailPageHeader} from "./InvoiceDetailPageHeader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Alert, LinearProgress, Paper, Stack, Tooltip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {DataGrid, GridColumnHeaderParams} from "@mui/x-data-grid";
import {TextValueField} from "../../../Utils/TextValueField";
import {AddressDisplay1Line} from "../../../Utils/Address";
import {useDispatch, useSelector} from "react-redux";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import ReorderIcon from "@mui/icons-material/Reorder";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import {retrieveCustomerById} from "../../../../../../corelogic/usecase/customer/customersActions";
import {getInvoiceLinesSelector, getInvoiceSelectedSelector} from "../../../../../../store/selectors/invoicesSelectors";
import {
    retrieveInvoiceLinesByInvoiceId,
    retrieveSelectedInvoice
} from "../../../../../../corelogic/usecase/invoices/invoicesActions";
import {Invoice, InvoiceLine} from "../../../../../../corelogic/models/invoice";
import {columnsInvoiceLineListDetailDescription} from "../../../Descriptions/invoiceLineListDetailDescription";
import InvoiceTotal from "../../../Utils/InvoiceTotal";


export const InvoiceDetailPageSelector = () => {
    const dispatch = useDispatch()
    const {invoiceId} = useParams()
    const {invoiceSelected, fetchingSelected} = useSelector(getInvoiceSelectedSelector)
    const {dataInvoiceLines, fetchingInvoiceLines} = useSelector(getInvoiceLinesSelector)

    useEffect(() => {
        const invoiceIdNum = Number(invoiceId)
        if (invoiceId && !isNaN(invoiceIdNum))
            dispatch(retrieveSelectedInvoice(invoiceIdNum))

        return () => dispatch<any>({type: APP_ACTIONS_TYPES.invoices.INVOICE_RESET_SELECTION})
    }, [dispatch, invoiceId])

    useEffect(() => {
        if (invoiceSelected) {
            dispatch(retrieveInvoiceLinesByInvoiceId(invoiceSelected?.id))
        }
    }, [invoiceSelected?.id, dispatch])

    return (
        fetchingSelected ? <LinearProgress color="primary"/>
            : invoiceSelected && !fetchingSelected ?
                <InvoiceDetailPage invoice={invoiceSelected} lines={dataInvoiceLines}
                                   fetchingInvoiceLines={fetchingInvoiceLines}/>
                : <h1>Order not found</h1>
    )
}

interface InvoiceDetailPageProps {
    invoice: Invoice,
    lines: InvoiceLine[]
    fetchingInvoiceLines: boolean
}

export const InvoiceDetailPage = (props: InvoiceDetailPageProps) => {
    const {invoice, lines = [], fetchingInvoiceLines} = props
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const {userLogged} = useSelector(getUserLoggedSelector)
    const [checked, setChecked] = useState(false)
    const [density, setDensity] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })

    useEffect(() => {
        if (invoice.billedCustomer?.id)
            dispatch(retrieveCustomerById(invoice.billedCustomer?.id))
    }, [dispatch, invoice.billedCustomer?.id]);

    const columns: GridColDef[] = [
        ...columnsInvoiceLineListDetailDescription.map(cd => ({
            ...cd,
            headerName: cd.headerName ? intl.formatMessage(getMessageDescriptor(cd.headerName)) : "",
            headerClassName: 'header-ellipsis',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title={cd.headerName ? intl.formatMessage(getMessageDescriptor(cd.headerName)) : ""}>
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            )
        })),
        {
            field: 'originalSalesDocument',
            headerName: intl.formatMessage(getMessageDescriptor("invoiceLineGridColOriginalSalesDocument")),
            headerClassName: 'header-ellipsis',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title={intl.formatMessage(getMessageDescriptor("invoiceLineGridColOriginalSalesDocument"))}>
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            ),
            flex: 1,
            renderCell: (params) => {
                const {originalSalesDocument, originalSalesDocumentType} = params.row;
                if (originalSalesDocument?.id && originalSalesDocumentType === 'VC') {
                    return (
                        <span
                            style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                            onClick={() => navigation(generatePath("/orders/visualisation/:orderId", {orderId: String(originalSalesDocument.id)}))}
                        >
                        {`${originalSalesDocumentType} ${originalSalesDocument.numDocument || ''}`}
                    </span>
                    );
                }
                return `${originalSalesDocumentType || ''} ${originalSalesDocument?.numDocument || ''}`;
            },
            valueGetter: (params) => `${params.row.originalSalesDocumentType || ''} ${params.row.originalSalesDocument?.numDocument || ''}`,
        },
        {
            field: 'actions',
            description: `Actions d'un produit`,
            headerAlign: "center",
            align: "center",
            width: 50,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title="Changer la densité">
                            <ReorderIcon onClick={() => {
                                setPaginationModel(prev => ({
                                    ...prev,
                                    pageSize: prev.pageSize < 50 ? 50 : prev.pageSize
                                }))
                                setDensity(prevState => !prevState)
                            }}
                                         sx={{
                                             fill: "rgba(33, 150, 243, 1)",
                                             fontSize: "1.5rem",
                                             cursor: "pointer"
                                         }}/>
                        </Tooltip>
                    </Box>
                )
            }
        }
    ]

    const handleChange = () => {
        setChecked(!checked)
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <InvoiceDetailPageHeader invoice={invoice}
                                         isCustomer={userLogged?.role?.code === UserRoleCode.CUSTOMER}/>
            </Paper>
            <Paper elevation={5} sx={{mx: 5, my: 1, py: 1, px: 3}}>
                <Grid container direction="column">
                    <Grid item container mb={1} justifyContent="space-around" alignItems="center">
                        <Grid item xl={4} sm={12} xs={12}>
                            <Tooltip title={invoice.reference ?? ""}>
                                <TextValueField
                                    style={{fontWeight: "bolder"}}
                                    text={intl.formatMessage(getMessageDescriptor("invoiceReference"))}
                                    value={invoice.reference ?? ""}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={3} sm={12} xs={12}>
                            <Grid container>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TextValueField
                                                style={{fontWeight: "bolder"}}
                                                text={intl.formatMessage(getMessageDescriptor("invoiceBillingDate"))}
                                                value={invoice.billingDate ? invoice.billingDate?.toLocaleString().slice(0, 10) : ""}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={5} sm={12} xs={12} container>
                            <Grid item>
                                <Typography>
                                    <FormattedMessage id={messages.invoiceBilledAddress.id}/> :
                                </Typography>
                            </Grid>
                            <Grid item ml={1}>
                                <AddressDisplay1Line
                                    hasFocus
                                    bolder
                                    address1={invoice.invoiceAddress1}
                                    address2={invoice.invoiceAddress2}
                                    address3={invoice.invoiceAddress3}
                                    zipCode1={invoice.invoiceAddressZipCode1}
                                    city={invoice.invoiceAddressCity}
                                    country={invoice.invoiceAddressCountry?.label}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={12} sx={{width: '100%', minWidth: 0, maxWidth: '100%', overflow: 'hidden'}}>
                            <Paper elevation={3}>
                                <Grid container height="60vh">
                                    <DataGrid
                                        rows={lines}
                                        columns={columns}
                                        columnVisibilityModel={{id: false}}
                                        paginationModel={paginationModel}
                                        pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                                        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                                        loading={fetchingInvoiceLines}
                                        density={density ? "compact" : "standard"}
                                        disableRowSelectionOnClick
                                        slots={{
                                            noRowsOverlay: () => (
                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                    <Alert severity="info">
                                                        {intl.formatMessage(getMessageDescriptor("orderLinesNoData"))}
                                                    </Alert>
                                                </Stack>
                                            )
                                        }}
                                        sx={{
                                            border: "1", flexWrap: "nowrap", width: "100%",
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: "none"
                                            },
                                            "& .MuiDataGrid-columnHeader:focus": {
                                                outline: "none"
                                            }
                                        }}/>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item width="100%">
                            <Grid container spacing={1}>
                                <Grid item lg={12} sm={12}>
                                    <Paper sx={{height: "100%"}}>
                                        <InvoiceTotal totalGrossTaxExcluded={invoice.totals?.totalGrossTaxExcluded}
                                                      totalShippingCost={invoice.totals?.totalShippingCosts}
                                                      discount={invoice.totals?.discount}
                                                      totalTaxExcluded={invoice.totals?.totalTaxExcluded}
                                                      totalVat={invoice.totals?.totalVat}
                                                      totalWithTax={invoice.totals?.totalWithTax}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}