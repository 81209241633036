import {AppState} from "store/appState";
import {UserRoleCode} from "../../corelogic/models/user";

export const getUserLoggedSelector = (state: AppState) => {
    return {
        userLogged: state.session.userLogged,
        fetching: state.session.fetching,
        isCustomer: (state.session?.userLogged?.role?.code === UserRoleCode.CUSTOMER)
    }
}

export const getActiveAuthTokenSelector = (state: AppState) => {
    return {activeAuthToken: state.session.activeAuthToken, fetching: state.session.fetching}
}

export const getCurrentEnvironment = (state: AppState) => {
    return {currentDepot: state.session.currentDepot}
}