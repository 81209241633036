export const API_URL = process.env.REACT_APP_API_ENDPOINT

export const API_PREFIX = {
    authentication: "auth",
    customerAddress: "customer/address",
    customer: "customer",
    item: "item",
    itemFilter: "item/filter",
    itemFavorites: "item/favorites",
    itemPackaging: "item/packaging",
    customerItemReference: "item/reference",
    order: "order",
    orderLine: "order/line",
    user: "user",
    society: "society",
    stats: "stats",
    tools: "tools",
    parameter: "parameter",
    server: "server",
    invoice: "invoice",
    invoiceFilter: "invoice/filter",
    invoiceLine: "invoice/line",
}

export const API_ERRORS_TYPE = {
    BAD_CREDENTIALS: "BAD_CREDENTIALS",
    INVALID_ARGUMENTS: "INVALID_ARGUMENTS",
    CONFLICT: "CONFLICT",
    NOT_FOUND: "NOT_FOUND"
}
