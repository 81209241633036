import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {ItemsListPage} from "../Pages/Item/ItemListPage/ItemsListPage";
import {ItemDetailsStore} from "../Pages/Item/ItemDetailsPage/ItemDetailsPage";
import {Home} from "../Home";
import {OrderListPage} from "../Pages/Order/OrderListPage/OrderListPage";
import {OrderCreationPageSelector} from "../Pages/Order/OrderCreationPage/OrderCreationPage";
import {OrderVisualisationPageSelector} from "../Pages/Order/OrderVisualisationPage/OrderVisualisationPage";
import {UserPageStore} from "../Pages/User/UserDetails/UserPage";
import {CustomerPageStore} from "../Pages/Customer/Details/CustomerPage";
import CustomerListPage from "../Pages/Customer/List/CustomerListPage";
import CustomerReferencingPage from "../Pages/CustomerItemReferencing/CustomerReferencingPage";
import UserCreationPage from "../Pages/User/UserCreation/UserCreationPage";
import {SocietyPageStore} from "../Pages/Society/SocietyPage";
import CustomerReferencingFamiliesPage from "../Pages/CustomerItemReferencing/CustomerReferencingFamiliesPage";
import {UserRoleCode} from "../../../../corelogic/models/user";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../store/selectors/sessionSelector";
import UserListPage from "../Pages/User/UserList/UserListPage";
import {AdminPage} from "../Pages/Admin/AdminPage";
import {InvoiceListPage} from "../Pages/Invoice/InvoiceListPage";
import {InvoiceDetailPageSelector} from "../Pages/Invoice/InvoiceDetailPage/InvoiceDetailPage";

const ProtectedRoute: React.FC<{
    requiredRoles: UserRoleCode[]}> = ({requiredRoles}) => {
    const {userLogged} = useSelector(getUserLoggedSelector)
    const isAuthorized = userLogged?.role?.code === UserRoleCode.ADMIN || (userLogged?.role?.code && requiredRoles.includes(userLogged?.role?.code as UserRoleCode))

    return (
        isAuthorized ? <Outlet/> : <Navigate to="/unauthorized"/>
    )
}
const NavigationRoutes = () => {
    const {userLogged} = useSelector(getUserLoggedSelector)
    return (
        <Routes>
            <Route index element={userLogged?.role?.code === UserRoleCode.CUSTOMER ? <OrderListPage/> : <Home/>}/>
            <Route path="/orders" element={<OrderListPage/>}/>
            <Route path="/orders/creation" element={<OrderCreationPageSelector/>}/>
            <Route path="/orders/visualisation/:orderId" element={<OrderVisualisationPageSelector/>}/>
            <Route path="/orders/update/:orderId" element={<OrderCreationPageSelector/>}/>
            <Route path="/invoices" element={<InvoiceListPage/>}/>
            <Route path="/invoices/:invoiceId" element={<InvoiceDetailPageSelector/>}/>
            <Route path="/items" element={<ItemsListPage/>}/>
            <Route path="/items/:itemId" element={<ItemDetailsStore/>}/>
            <Route path="/users" element={<ProtectedRoute requiredRoles={[]}/>}>
                <Route path="/users" element={<UserListPage/>}/>
            </Route>
            <Route path="/users/:userId" element={<UserPageStore/>}/>
            <Route path="/users/creation" element={<ProtectedRoute requiredRoles={[]}/>}>
                <Route path="/users/creation" element={<UserCreationPage/>}/>
            </Route>
            <Route path="/customers" element={<ProtectedRoute requiredRoles={[UserRoleCode.COMMERCIAL]}/>}>
                <Route path="/customers" element={<CustomerListPage/>}/>
            </Route>
            <Route path="/customers/creation" element={<ProtectedRoute requiredRoles={[]}/>}>
                <Route path="/customers/creation" element={<CustomerPageStore/>}/>
            </Route>
            <Route path="/customers/:customerId" element={<CustomerPageStore/>}/>
            <Route path="/customers/referencing" element={<ProtectedRoute requiredRoles={[UserRoleCode.COMMERCIAL]}/>}>
                <Route path="/customers/referencing" element={<CustomerReferencingPage/>}/>
            </Route>
            <Route path="/customers/referencing/families"
                   element={<ProtectedRoute requiredRoles={[UserRoleCode.COMMERCIAL]}/>}>
                <Route path="/customers/referencing/families" element={<CustomerReferencingFamiliesPage/>}/>
            </Route>
            <Route path="/society/:societyId" element={<ProtectedRoute requiredRoles={[UserRoleCode.COMMERCIAL]}/>}>
                <Route path="/society/:societyId" element={<SocietyPageStore/>}/>
            </Route>
            <Route path="/admin" element={<ProtectedRoute requiredRoles={[UserRoleCode.ADMIN]}/>}>
                <Route path="/admin" element={<AdminPage/>}/>
            </Route>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default NavigationRoutes