import {AnyAction, combineReducers} from "redux"
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes"
import {Society, SocietyAllFormData} from "../../corelogic/models/society";

const emptyFormData = () => ({
    languages: [],
    businessForms: [],
    currencies: [],
    countries: [],
    vatTypes: []
} as SocietyAllFormData)

const dataFormFields = (state: SocietyAllFormData = emptyFormData(), action: AnyAction): SocietyAllFormData | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.societies.SOCIETIES_FORM_DATA_FIELDS:
            return action.payload
        default:
            return state;
    }
}

const data = (state: Society[] = [], action: AnyAction): Society[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.societies.SOCIETIES_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.societies.SOCIETIES_RESET_DATA:
            return []
        default:
            return state;
    }
}

const selected = (state: Society | null = null, action: AnyAction): Society | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.societies.SOCIETY_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.societies.SOCIETY_UPDATE:
            return (state && state.id === action.payload.id) ? action.payload : state
        case APP_ACTIONS_TYPES.societies.SOCIETY_RESET_SELECTING:
            return null
        default:
            return state
    }
}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("SOCIETY")) {
        return action.type === APP_ACTIONS_TYPES.societies.SOCIETY_FETCHING
    }
    return state
}

export default combineReducers({
    data,
    fetching,
    selected,
    dataFormFields
})