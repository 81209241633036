import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Checkbox, Grid, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormattedNumber} from "react-intl";
import ImageIcon from '@mui/icons-material/Image';
import {FormattedPercentageNumber} from "../Utils/FormattedDisplayNumber";

export const columnsInvoiceLineListDetailDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: 'invoiceLineGridColPictureId',
        type: 'number',
        flex: 0.5
    },
    {
        field: 'picture',
        headerName: 'invoiceLineGridColPicture',
        headerAlign: "center",
        filterable: false,
        sortable: false,
        flex: 0.5,
        renderHeader: () => <ImageIcon/>,
        renderCell: (cellValues) => (
            <Grid container justifyContent="center">
                <img alt={cellValues.row.item?.label || ""}
                     src={`${process.env.REACT_APP_API_ENDPOINT}img/items/${cellValues.row.item?.code.replace("/", "_")}.jpg`}
                     onError={({currentTarget}) => {
                         currentTarget.onerror = null;
                         currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`;
                     }}
                     style={{height: "60px", width: "auto", backgroundSize: "cover", objectFit: "cover"}}/>
            </Grid>
        )
    },
    {
        field: 'itemCode',
        headerName: 'invoiceLineGridColItemCode',
        type: "string",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (cellValues) => (
            <Tooltip title={`${cellValues.row.item?.code || ''}`}>
                <Box>
                    <Typography fontSize="small">
                        {cellValues.row.item?.code || ''}
                    </Typography>
                </Box>
            </Tooltip>
        ),
        valueGetter: (cellValues) => cellValues.row.item?.code
    },
    {
        field: 'label',
        headerName: 'invoiceLineGridColLabel',
        type: "string",
        flex: 1.5,
        headerAlign: "center",
        align: "left",
        renderCell: (cellValues) => (
            <Tooltip title={`${cellValues.row.label || ''}`}>
                <Box>
                    <Typography fontSize="small">
                        {cellValues.row.label || ''}
                    </Typography>
                </Box>
            </Tooltip>
        ),
        valueGetter: (cellValues) => cellValues.row.label
    },
    {
        field: 'quantity',
        headerName: 'invoiceLineGridColQuantity',
        type: "number",
        flex: 0.75,
        headerAlign: "center",
        align: "center",
        valueGetter: (cellValues) => cellValues.row.quantity
    },
    {
        field: 'unit',
        headerName: 'invoiceLineGridColUnit',
        type: "string",
        headerAlign: "center",
        align: "center",
        flex: 0.75,
        renderCell: (cellValues) => (
            <Typography fontSize="small">
                {cellValues.row.unit || ''}
            </Typography>
        ),
        valueGetter: (cellValues) => cellValues.row.unit
    },
    {
        field: 'byHowMuch',
        headerName: 'invoiceLineGridColByHowMuch',
        type: 'number',
        flex: 0.5,
    },
    {
        field: 'quantitySecondUnit',
        headerName: 'invoiceLineGridColQuantitySecondUnit',
        type: 'number',
        flex: 0.6,
    },
    {
        field: 'secondUnit',
        headerName: 'invoiceLineGridColSecondUnit',
        flex: 0.6,
    },
    {
        field: 'vat',
        headerName: 'invoiceLineGridColVAT',
        flex: 0.5,
        renderCell: (cellValues) => (
            <FormattedPercentageNumber value={cellValues.row.vat.rate || 0}/>
        ),
        valueGetter: (params) => {
            return params.row.vat;
        }
    },
    {
        field: 'grossPrice',
        headerName: 'invoiceLineGridColGrossPrice',
        type: 'number',
        headerAlign: "center",
        align: "center",
        flex: 0.8,
        renderCell: (cellValues) => (
            <FormattedNumber
                value={cellValues.row.grossPrice || 0}
                maximumFractionDigits={2}
                style="currency" currency="EUR"/>
        ),
        valueGetter: (cellValues) => cellValues.row.grossPrice
    },
    {
        field: 'discount1',
        headerName: 'invoiceLineGridColDiscount1',
        headerAlign: "center",
        align: "center",
        type: "number",
        flex: 0.5,
        sortable: false,
        filterable: false,
        disableReorder: true,
        resizable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => (
            <Box>
                <FormattedNumber value={cellValues.row.discount1 || 0} maximumFractionDigits={2} style="percent"/>
            </Box>
        )
    },
    {
        field: 'discount2',
        headerName: 'invoiceLineGridColDiscount2',
        headerAlign: "center",
        align: "center",
        type: "number",
        flex: 0.5,
        sortable: false,
        filterable: false,
        disableReorder: true,
        resizable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => (
            <Box>
                <FormattedNumber value={cellValues.row.discount2 || 0} maximumFractionDigits={2} style="percent"/>
            </Box>
        )
    },
    {
        field: 'netPrice',
        headerName: 'invoiceLineGridColNetPrice',
        type: 'number',
        headerAlign: "center",
        align: "center",
        flex: 0.8,
        renderCell: (cellValues) => (
            <FormattedNumber
                value={cellValues.row.netPrice || 0}
                maximumFractionDigits={2}
                style="currency" currency="EUR"/>
        ),
        valueGetter: (cellValues) => cellValues.row.netPrice
    },
    {
        field: 'totalExTax',
        headerName: 'invoiceLineGridColTotalExTax',
        type: 'number',
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (cellValues) => (
            <FormattedNumber
                value={(cellValues.row.netPrice) * (cellValues.row.quantity) || 0}
                maximumFractionDigits={2}
                style="currency" currency="EUR"/>
        ),
        valueGetter: (cellValues) => Number((cellValues.row.netPrice) * Number(cellValues.row.quantity)).toFixed(2)
    },
    {
        field: 'free',
        headerName: 'invoiceLineGridColFree',
        type: 'boolean',
        flex: 0.5,
        renderCell: (params) => (
            <Checkbox checked={params.value} disabled/>
        ),
    }
];