import {EnumData} from "../../../../corelogic/models/parameter";

export enum WeightUnit {
    KG = "Kilo-gramme",
    GR = "Gramme"
}

export enum VolumeUnit {
    M3 = "Mètre cube"
}

export enum DimensionUnit {
    CM = "Centimètre"
}

export enum BarecodeType {
    EAN13 = "EAN-13",
}

export enum UserStatus {
    ACTIVE = "Actif",
    INACTIVE = "Inactif",
    IN_PROGRESS = "En cours de création"
}

export enum UserStatusV2 {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    IN_PROGRESS = "IN_PROGRESS"
}

export enum OrderStatus {
    CREATION = "Création de commande",
    NEW = "Nouvelle",
    AWAITING_VALIDATION = "En attente de validation",
    DEALING_WITH = "Prise en charge",
    BLOCKED = "Bloquée",
    PARTIALLY_SHIPPED = "Expédiée partielle",
    SHIPPED = "Expédiée",
    PARTIALLY_BILLED = "Facturée partielle",
    BILLED = "Facturée",
    CANCELED = "Annulée",
    DELETED = "Supprimée",
    UNDEFINED = "Inconnu"
}

export enum CustomerItemReferencingType {
    REFERENCING_BY_SUPER_FAMILY = "sf",
    REFERENCING_BY_FAMILY = "f",
    REFERENCING_BY_CUSTOMER = "c"
}

export enum StatsModeView {
    BASICS,
    ITEMS_BY_TOTALWT,
    ITEMS_BY_QTY,
    CUSTOMERS_BY_TOTALWT,
}

export enum GraphsModeView {
    MODE_1 = "m1",
    MODE_2 = "m2",
}

export enum SalesTypeDoc {
    COMMANDE = "COMMANDE",
    LIVRAISON = "LIVRAISON",
}

export const enumToEnumData = (enumType: { [k: string]: string }, value: string): EnumData => {
    const keyEnum = getEnumKeyFromValue(enumType, value)
    if (keyEnum)
        return {value: keyEnum, label: value}

    return {value: "", label: ""}
}

export const getEnumKeyFromValue = (enumType: { [k: string]: any }, value: any) => {
    const keys = Object.keys(enumType).filter((x) => enumType[x] === value)
    return keys.length > 0 ? keys[0] : ''
}

export const getEnumValue = (enumType: { [k: string]: any }, key?: string) => {
    if (key)
        return enumType[key]
}

