import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Invoice, InvoiceLine} from "../../corelogic/models/invoice";

const data = (state: Invoice[] = [], action: AnyAction): Invoice[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.invoices.INVOICES_RETRIEVED:
            return action.payload.invoices
        case APP_ACTIONS_TYPES.invoices.INVOICE_RESET_DATA:
            return []
        default:
            return state;
    }
}

const count = (state: number = 0, action: AnyAction): number => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.invoices.INVOICES_RETRIEVED:
            return action.payload.count
        case APP_ACTIONS_TYPES.invoices.INVOICE_RESET_DATA:
            return 0
        default:
            return state;
    }
}

const selected = (state: Invoice | null = null, action: AnyAction): Invoice | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.invoices.INVOICE_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.invoices.INVOICE_RESET_SELECTION:
            return null
        default:
            return state;
    }
}

const selectedLines = (state: InvoiceLine[] = [], action: AnyAction): InvoiceLine[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.invoices.INVOICE_SELECTING:
        case APP_ACTIONS_TYPES.invoices.INVOICE_RESET_SELECTION:
            return []
        case APP_ACTIONS_TYPES.invoices.INVOICE_LINES_RETRIEVED:
            return action.payload
        default:
            return state;
    }
}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("INVOICES")) {
        return action.type === APP_ACTIONS_TYPES.invoices.INVOICES_RETRIEVING
    }
    return state
}

const fetchingOnAction = (state: boolean = false, action: AnyAction) => {
    return action.type === APP_ACTIONS_TYPES.invoices.INVOICE_FETCHING
}

/** INVOICE LINES **/

const fetchingInvoiceLines = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("INVOICE_LINE")) {
        return action.type === APP_ACTIONS_TYPES.invoices.INVOICE_LINES_RETRIEVING
    }
    return state
}

export default combineReducers({
    data,
    count,
    selected,
    fetching,
    fetchingOnAction,
    fetchingInvoiceLines,
    selectedLines
})