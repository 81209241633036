import {OrderVisualisationPageHeader} from "./OrderVisualisationPageHeader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Alert, Collapse, IconButton, LinearProgress, Paper, Stack, TextField, Tooltip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {DividingHR} from "../../../Utils/DividingHR";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import {TextValueField} from "../../../Utils/TextValueField";
import {AddressDisplay1Line} from "../../../Utils/Address";
import {Order, OrderLine, OrderLineFilter} from "../../../../../../corelogic/models/order";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getOrderLinesSelector, getOrderSelectedSelector} from "../../../../../../store/selectors/ordersSelectors";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {
    retrieveFilteredOrderLinesByOrderId,
    retrieveSelectedOrder
} from "../../../../../../corelogic/usecase/orders/ordersActions";
import {OrderCommentsListStore} from "../OrderCommentsList";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {
    columnsOrderVisualisationLinesListDescription
} from "../../../Descriptions/orderLineListVisualisationDescription";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import ReorderIcon from "@mui/icons-material/Reorder";
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import OrderTotal from "../../../Utils/OrderTotal";
import {OrderStatus} from "../../../../../secondary/InMemory/Data/enums";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import {retrieveCustomerById} from "../../../../../../corelogic/usecase/customer/customersActions";
import {getCustomerSelectedSelector} from "../../../../../../store/selectors/customersSelector";


export const OrderVisualisationPageSelector = () => {
    const dispatch = useDispatch()
    const {orderId} = useParams()
    const {orderSelected, fetchingSelected} = useSelector(getOrderSelectedSelector)
    const {dataOrderLines, fetchingOrderLines} = useSelector(getOrderLinesSelector)
    const [orderLineFilter, setOrderLineFilter] = useState<OrderLineFilter>({
        search: ""
    })

    useEffect(() => {
        const orderIdNum = Number(orderId)
        if (orderId && !isNaN(orderIdNum))
            dispatch(retrieveSelectedOrder(orderIdNum))

        return () => dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION})
    }, [dispatch, orderId])

    useEffect(() => {
        if (orderSelected?.id) {
            dispatch(retrieveFilteredOrderLinesByOrderId(orderSelected?.id, !orderLineFilter.search ? orderLineFilter : {
                ...orderLineFilter,
                search: ""
            }))
        }
    }, [orderSelected?.id, dispatch, orderLineFilter, orderSelected])

    return (
        fetchingSelected ? <LinearProgress color="primary"/>
            : orderSelected && !fetchingSelected ?
                <OrderVisualisationPage order={orderSelected} lines={dataOrderLines}
                                        fetchingOrderLines={fetchingOrderLines}/>
                : <h1>Order not found</h1>
    )
}

interface OrderVisualisationPageProps {
    order: Order,
    lines: OrderLine[]
    fetchingOrderLines: boolean
}

export const OrderVisualisationPage = (props: OrderVisualisationPageProps) => {
    const {order, lines = [], fetchingOrderLines} = props
    const intl = useIntl()
    const dispatch = useDispatch()
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSelected} = useSelector(getCustomerSelectedSelector)
    const [checked, setChecked] = useState(false)
    const [density, setDensity] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })

    useEffect(() => {
        if (order.orderedCustomer?.id)
            dispatch(retrieveCustomerById(order.orderedCustomer?.id))
    }, [dispatch, order.orderedCustomer?.id]);

    const columns: GridColDef[] = [
        ...columnsOrderVisualisationLinesListDescription.map(cd => ({
            ...cd,
            headerName: cd.headerName ? intl.formatMessage(getMessageDescriptor(cd.headerName)) : "",
        })),
        {
            field: 'actions',
            description: `Actions d'un produit`,
            headerAlign: "center",
            align: "center",
            width: 50,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title="Changer la densité">
                            <ReorderIcon onClick={() => setDensity(prevState => !prevState)}
                                         sx={{
                                             fill: "rgba(33, 150, 243, 1)",
                                             fontSize: "1.5rem",
                                             cursor: "pointer"
                                         }}/>
                        </Tooltip>
                    </Box>
                )
            }
        }
    ]

    const handleChange = () => {
        setChecked(!checked)
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <OrderVisualisationPageHeader order={order}
                                              isCustomer={userLogged?.role?.code === UserRoleCode.CUSTOMER}/>
            </Paper>
            <Paper elevation={5} sx={{mx: 5, my: 1, py: 1, px: 3}}>
                <Grid container direction="column">
                    <Grid item container alignItems="center" mb={1}>
                        <Grid item sm={1}>
                            {!checked ?
                                <Tooltip
                                    title={intl.formatMessage(getMessageDescriptor("orderCommentsToggleTooltip"))}>
                                    <IconButton onClick={handleChange}>
                                        <CommentOutlinedIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip
                                    title={intl.formatMessage(getMessageDescriptor("orderCommentsToggleCloseTooltip"))}>
                                    <IconButton onClick={handleChange}>
                                        <CommentsDisabledOutlinedIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Tooltip>}
                        </Grid>
                        <Grid container item lg={6} sm={12} alignItems="center">
                            <Grid item xl={6} sm={12}>
                                <TextValueField
                                    style={{fontWeight: "bolder"}}
                                    text={intl.formatMessage(getMessageDescriptor("orderReference"))}
                                    value={order.reference ? order.reference : ""}/>
                            </Grid>
                            <Grid item xl={6} sm={12}>
                                <Grid container>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <TextValueField
                                                    style={{fontWeight: "bolder"}}
                                                    text={order.status === OrderStatus.BILLED || order.status === OrderStatus.DEALING_WITH || order.status === OrderStatus.SHIPPED ? intl.formatMessage(getMessageDescriptor("orderDelayConfirmed")) : intl.formatMessage(getMessageDescriptor("orderDelayRequested"))}
                                                    value={order.delayRequested ? order.delayRequested?.toLocaleString().slice(0, 10) : ""}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} sm={12}>
                            <Grid container>
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage id={messages.orderDeliveryAddress.id}/> :
                                    </Typography>
                                </Grid>
                                <Grid item ml={1}>
                                    <AddressDisplay1Line
                                        hasFocus
                                        bolder
                                        address1={order.deliveryAddress1}
                                        address2={order.deliveryAddress2}
                                        address3={order.deliveryAddress3}
                                        zipCode1={order.deliveryAddressZipCode1}
                                        city={order.deliveryAddressCity}
                                        country={order.deliveryAddressCountry?.label}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Paper elevation={3}>
                                    <Grid container height="58vh">
                                        <Collapse orientation="horizontal" in={checked}>
                                            <OrderCommentsListStore orderId={order.id}/>
                                        </Collapse>
                                        <DataGrid
                                            rows={lines}
                                            columns={columns}
                                            columnVisibilityModel={{id: false}}
                                            paginationModel={paginationModel}
                                            pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                                            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                                            loading={fetchingOrderLines}
                                            density={density ? "compact" : "standard"}
                                            disableRowSelectionOnClick
                                            slots={{
                                                noRowsOverlay: () => (
                                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                                        <Alert severity="info">
                                                            {intl.formatMessage(getMessageDescriptor("orderLinesNoData"))}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }}
                                            sx={{
                                                border: "1", flexWrap: "nowrap", width: "100%",
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: "none"
                                                },
                                                "& .MuiDataGrid-columnHeader:focus": {
                                                    outline: "none"
                                                }
                                            }}/>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item width="100%">
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={12}>
                                        <Paper sx={{height: "100%"}}>
                                            <Grid container direction="column" pt={1}>
                                                <Grid item>
                                                    <Typography fontWeight="bolder"
                                                                textAlign="center"><FormattedMessage
                                                        id={messages.orderListPageCommentsOrder.id}/></Typography>
                                                </Grid>
                                                <DividingHR/>
                                                <Grid item>
                                                    <TextField
                                                        id="filled-textarea"
                                                        label={intl.formatMessage(getMessageDescriptor("orderCommentInputLabel"))}
                                                        placeholder="Votre commentaire..."
                                                        multiline
                                                        disabled
                                                        inputProps={{style: {color: "red"}}}
                                                        defaultValue={order.comment ? order.comment : intl.formatMessage(getMessageDescriptor("orderCommentsEmpty"))}
                                                        size="medium"
                                                        maxRows={4}
                                                        minRows={3}
                                                        fullWidth
                                                        variant="filled"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item lg={8} sm={12}>
                                        <Paper sx={{height: "100%"}}>
                                            <OrderTotal totalGrossTaxExcluded={order.totals?.totalGrossTaxExcluded}
                                                        totalShippingCost={order.totals?.totalShippingCost}
                                                        shippingCostForced={order.totals?.shippingCostForced}
                                                        discount={order.totals?.discount}
                                                        totalTaxExcluded={order.totals?.totalTaxExcluded}
                                                        totalVat={order.totals?.totalVat}
                                                        totalWithTax={order.totals?.totalWithTax}
                                                        vatExcluded={order.vatManagement?.value === "E"}
                                                        customer={customerSelected}
                                                        orderId={order.id}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}