import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import qs from "qs";
import {InvoiceGateway} from "corelogic/gateways/InvoiceGateway";
import {Invoice, InvoiceFilter, InvoiceWithCount} from "corelogic/models/invoice";
import {
    InvoiceApiResponse,
    InvoiceApiWithCountResponse,
    mapInvoiceApiResponseToInvoice,
    mapInvoiceFilterToInvoiceFilterApi
} from "./Model/invoiceApi";
import {PaginationFilter} from "../../../corelogic/models/item";

export class ApiInvoiceGateway implements InvoiceGateway {
    async retrieveAllFiltered(filter: InvoiceFilter, pagination: PaginationFilter): Promise<InvoiceWithCount> {
        const response = await apiFetchApiResponse<InvoiceApiWithCountResponse>({
            url: buildUrl(API_PREFIX.invoiceFilter),
            params: { ...mapInvoiceFilterToInvoiceFilterApi(filter), ...pagination },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' })
        });
        return {
            count: response.count,
            invoices: response.invoices.map(mapInvoiceApiResponseToInvoice)
        };
    }

    async retrieveById(id: number): Promise<Invoice> {
        const x = await apiFetchApiResponse<InvoiceApiResponse>({url: buildUrl(API_PREFIX.invoice, id.toString())});
        return mapInvoiceApiResponseToInvoice(x);
    }

}