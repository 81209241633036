import {AppState} from "store/appState";

export const getInvoicesSelector = (state: AppState) => {
    return {
        data: state.invoices.data,
        fetching: state.invoices.fetching,
        invoiceCount: state.invoices.count
    }
}

export const getInvoiceLinesSelector = (state: AppState) => {
    return {
        dataInvoiceLines: state.invoices.selectedLines,
        fetchingInvoiceLines: state.invoices.fetchingInvoiceLines
    }
}

export const getInvoiceSelectedSelector = (state: AppState) => {
    return {
        invoiceSelected: state.invoices.selected,
        fetchingSelected: state.invoices.fetchingOnAction
    }
}