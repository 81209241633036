import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DataGrid, GridActionsCellItem, GridRowParams,} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CommentIcon from '@mui/icons-material/Comment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Stack,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import {columnsOrdersListPageListDescription} from "../../../Descriptions/orderListDescription";
import {OrderListPageHeader} from "./OrderListPageHeader";
import '../../../Style/Pages/OrderPages/_OrderListPage.scss'
import {useDispatch, useSelector} from "react-redux";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {
    deleteOrder,
    duplicateOrder,
    retrieveAllOrdersFiltered,
    retrieveFilteredOrderLinesByOrderId,
    retrieveSelectedOrder,
    submitOrder
} from "../../../../../../corelogic/usecase/orders/ordersActions";
import {
    getOrderLinesSelector,
    getOrderSelectedSelector,
    getOrdersSelector
} from "../../../../../../store/selectors/ordersSelectors";
import {OrderStatus} from "../../../../../secondary/InMemory/Data/enums";
import {generatePath, useNavigate} from "react-router-dom";
import {OrderCommentsListStore} from "../OrderCommentsList";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import 'react-toastify/dist/ReactToastify.css';
import {OrderFilter} from "../../../../../../corelogic/models/order";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReorderIcon from '@mui/icons-material/Reorder';
import {DividingHR} from "../../../Utils/DividingHR";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import OrderFilterAccordeon from "./OrderFilterAccordeon";
import moment from "moment";
import {UserRoleCode} from "../../../../../../corelogic/models/user";

const enum Actions {
    DELETE,
    UPDATE,
    VIEW,
    DUPLICATE,
    SHOW_COMMENTS,
    SUBMIT_START,
    SUBMIT_CONFIRM,
    PRINT_PURCHASE_ORDER,
    PRINT_DELIVERY_NOTE

}

export const OrderListPage = () => {
    const dispatch = useDispatch()
    const {data, fetching} = useSelector(getOrdersSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {orderSelected} = useSelector(getOrderSelectedSelector)
    const {dataOrderLines} = useSelector(getOrderLinesSelector)
    const intl = useIntl()
    const navigation = useNavigate()
    const [toggleFilters, setToggleFilters] = useState<boolean>(false)
    const [orderId, setOrderId] = useState(0)
    const [orderFilter, setOrderFilter] = useState<OrderFilter>({
        search: "",
        st: [],
        cd: moment().subtract(1, 'months').set({D: 1}).toDate().getTime()
    })
    const [density, setDensity] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [filterCount, setFilterCount] = useState(1)
    /** COMMENTS MODAL **/
    const [openDialogOrderComment, setOpenDialogOrderComment] = useState(false)
    const [idForDeletion, setIdForDeletion] = useState<null | number>(null)
    /** ERROR MODAL **/
    const [openModalErrorSubmit, setOpenModalErrorSubmit] = useState(false)
    const [openModalSubmit, setOpenModalSubmit] = useState(false)

    useEffect(() => {
        dispatch(retrieveAllOrdersFiltered(orderFilter))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_DATA})
    }, [dispatch, orderFilter])

    useEffect(() => {
        if (orderSelected && dataOrderLines &&
            (!orderSelected.deliveryAddressBusinessName || !orderSelected.deliveryAddress1 || !orderSelected.deliveryAddressZipCode1 || !orderSelected.deliveryAddressCity ||
                moment(orderSelected.delayRequested) < moment() || dataOrderLines.length <= 0)) {
            setOpenModalErrorSubmit(true)
        } else if (orderSelected && userLogged?.role?.code === UserRoleCode.ADMIN && [OrderStatus.AWAITING_VALIDATION].includes(orderSelected.status || OrderStatus.UNDEFINED)) {
            setOpenModalSubmit(true)
        } else if (openModalSubmit) {
            setOpenModalSubmit(false)
        } else if (openModalErrorSubmit) {
            setOpenModalErrorSubmit(false)
        }
    }, [dataOrderLines, openModalErrorSubmit, openModalSubmit, orderSelected]);

    const handleCloseDialogOrderComment = () => {
        setOpenDialogOrderComment(false)
    }

    const handleRefresh = useCallback(() => {
        dispatch(retrieveAllOrdersFiltered(orderFilter))
    }, [dispatch, orderFilter])
    const handleDeleteOrder = () => {
        const id = Number(idForDeletion)
        if (!isNaN(id))
            dispatch(deleteOrder(id))
        setIdForDeletion(null)
        setTimeout(() => {
            handleRefresh()
        }, 1000)
    }

    const handleCloseDialogOrderDeletion = () => {
        setIdForDeletion(null)
    }

    const handleActions = useCallback((action: Actions, id: number) => {

        switch (action) {
            case Actions.DELETE:
                setIdForDeletion(id)
                break
            case Actions.UPDATE:
                navigation(generatePath("/orders/update/:orderId", {orderId: String(id)}))
                break
            case Actions.VIEW:
                navigation(generatePath("/orders/visualisation/:orderId", {orderId: String(id)}))
                break
            case Actions.DUPLICATE:
                dispatch(duplicateOrder(id))
                navigation(generatePath("/orders/creation"))
                break
            case Actions.SHOW_COMMENTS:
                setOpenDialogOrderComment(true)
                setOrderId(id)
                break
            case Actions.SUBMIT_START:
                dispatch(retrieveSelectedOrder(id))
                dispatch(retrieveFilteredOrderLinesByOrderId(id, {}))
                setOrderId(id)
                break
            case Actions.SUBMIT_CONFIRM:
                dispatch(submitOrder(id))
                setOpenModalSubmit(false)
                dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION})
                break
            case Actions.PRINT_PURCHASE_ORDER:
                alert("Wip: Imprimer bon de commande")
                break
            case Actions.PRINT_DELIVERY_NOTE:
                alert("Wip: Imprimer bon de livraison")
                break
            default:
                break
        }
    }, [dispatch, navigation])

    const columns = useMemo<GridColDef[]>(
        () => [
            ...(userLogged?.linkedCustomer ? columnsOrdersListPageListDescription.filter(c => !["orderedCustomerCode", "orderedCustomerLabel"].includes(c.field))
                : columnsOrdersListPageListDescription)
                .map(cd => ({
                    ...cd,
                    headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
                    description: intl.formatMessage(getMessageDescriptor(cd.headerName)),
                })),
            {
                field: 'actions',
                type: 'actions',
                width: 180,
                sortable: false,
                editable: false,
                filterable: false,
                disableReorder: true,
                resizable: false,
                hideable: false,
                renderHeader: () => {
                    return (
                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                            <Tooltip title="Rafraîchir le tableau">
                                <RefreshIcon onClick={handleRefresh}
                                             sx={{
                                                 fill: "rgba(33, 150, 243, 1)",
                                                 fontSize: "1.5rem",
                                                 cursor: "pointer"
                                             }}/>
                            </Tooltip>
                            <Divider variant="middle"/>
                            <Tooltip title="Changer la densité">
                                <ReorderIcon onClick={() => setDensity(prevState => !prevState)}
                                             sx={{
                                                 fill: "rgba(33, 150, 243, 1)",
                                                 fontSize: "1.5rem",
                                                 cursor: "pointer"
                                             }}/>
                            </Tooltip>
                            {/* <GridToolbarExport printOptions={{disableToolbarButton: true}}
                                               csvOptions={{delimiter: ";"}}/>*/}
                        </Box>
                    )
                },
                getActions: (params: GridRowParams) => {
                    const orderListGridActions: React.ReactElement[] = []
                    const id = Number(params.id)
                    orderListGridActions.push([OrderStatus.NEW, OrderStatus.CREATION].includes(params.row.status) ?
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("orderGridLineActionUpdateOrder"))}>
                            <GridActionsCellItem
                                icon={<EditIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                                label={intl.formatMessage(getMessageDescriptor("orderGridLineActionUpdateOrder"))}
                                onClick={() => handleActions(Actions.UPDATE, id)}
                                showInMenu={false}/>
                        </Tooltip>
                        :
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("orderGridLineActionSeeOrder"))}>
                            <GridActionsCellItem
                                icon={<RemoveRedEyeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                                label={intl.formatMessage(getMessageDescriptor("orderGridLineActionSeeOrder"))}
                                onClick={() => handleActions(Actions.VIEW, id)}
                                showInMenu={false}/>
                        </Tooltip>
                    )
                    if ([OrderStatus.NEW, OrderStatus.CREATION].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<DeleteIcon sx={{fill: "rgba(255, 82, 82, 1)"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionDelete"))}
                            onClick={() => handleActions(Actions.DELETE, id)}
                            showInMenu={true}
                        />)
                    }
                    if ((userLogged?.role?.code === "ADMIN" || userLogged?.role?.code === "COMMERCIAL") && [OrderStatus.AWAITING_VALIDATION].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<DeleteIcon sx={{fill: "rgba(255, 82, 82, 1)"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionDelete"))}
                            onClick={() => handleActions(Actions.DELETE, id)}
                            showInMenu={true}
                        />)
                    }
                    if ([OrderStatus.BILLED, OrderStatus.SHIPPED, OrderStatus.DEALING_WITH].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<DeleteIcon sx={{fill: "grey"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionDelete"))}
                            disabled
                            onClick={() => handleActions(Actions.DELETE, id)}
                            showInMenu={true}
                        />)
                    }
                    if ([OrderStatus.BILLED, OrderStatus.SHIPPED, OrderStatus.AWAITING_VALIDATION, OrderStatus.DEALING_WITH, OrderStatus.CREATION, OrderStatus.NEW, OrderStatus.CANCELED, OrderStatus.DELETED].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<FileCopyIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionDuplicate"))}
                            onClick={() => handleActions(Actions.DUPLICATE, id)}
                            showInMenu={true}
                        />)
                    }
                    if (params.row.hasComments) {
                        orderListGridActions.push(
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("orderGridLineActionSeeComment"))}
                            >
                                <GridActionsCellItem
                                    icon={<CommentIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                                    placeholder="test"
                                    label={intl.formatMessage(getMessageDescriptor("orderGridLineActionSeeComment"))}
                                    onClick={() => handleActions(Actions.SHOW_COMMENTS, id)}
                                    showInMenu={false}
                                />
                            </Tooltip>
                        )
                    }
                    if (!params.row.hasComments) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<CommentIcon sx={{fill: "grey"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionSeeComment"))}
                            disabled
                            onClick={() => handleActions(Actions.SHOW_COMMENTS, id)}
                            showInMenu={false}
                        />)
                    }
                    if (((userLogged?.role?.code === "ADMIN") && [OrderStatus.NEW, OrderStatus.CREATION,OrderStatus.AWAITING_VALIDATION].includes(params.row.status)) /*|| [OrderStatus.NEW, OrderStatus.CREATION].includes(params.row.status)*/) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<CheckCircleIcon sx={{fill: "rgba(33, 150, 243, 1)", marginRight: "4px"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionSubmit"))}
                            onClick={() => handleActions(Actions.SUBMIT_START, id)}
                            showInMenu={true}
                        />)
                    }
                    if ((userLogged?.role?.code === "ADMIN") && [OrderStatus.BILLED, OrderStatus.SHIPPED].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<PrintIcon sx={{fill: "rgba(33, 150, 243, 1)", marginRight: "4px"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionPrintOrderBill"))}
                            onClick={() => handleActions(Actions.PRINT_PURCHASE_ORDER, id)}
                            showInMenu={true}
                        />)
                    }
                    if ((userLogged?.role?.code === "ADMIN") && [OrderStatus.NEW, OrderStatus.CREATION, OrderStatus.DEALING_WITH, OrderStatus.AWAITING_VALIDATION].includes(params.row.status)) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<PrintIcon sx={{fill: "grey", marginRight: "4px"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionPrintOrderBill"))}
                            onClick={() => handleActions(Actions.PRINT_PURCHASE_ORDER, id)}
                            showInMenu={true}
                            disabled
                        />)
                    }
                    if ((userLogged?.role?.code === "ADMIN") && params.row.status === OrderStatus.BILLED) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<PrintIcon sx={{fill: "rgba(33, 150, 243, 1)", marginRight: "4px"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionPrintDeliveryBill"))}
                            onClick={() => handleActions(Actions.PRINT_DELIVERY_NOTE, id)}
                            showInMenu={true}
                        />)
                    }
                    if ((userLogged?.role?.code === "ADMIN") && params.row.status !== OrderStatus.BILLED) {
                        orderListGridActions.push(<GridActionsCellItem
                            icon={<PrintIcon sx={{fill: "grey", marginRight: "4px"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("orderGridLineActionPrintDeliveryBill"))}
                            onClick={() => handleActions(Actions.PRINT_DELIVERY_NOTE, id)}
                            showInMenu={true}
                            disabled
                        />)
                    }
                    return orderListGridActions
                },
            },
        ],
        [userLogged?.linkedCustomer, userLogged?.role?.code, intl, handleRefresh, handleActions],
    )
    const getInputValue = useCallback((inputValue: string) => {
        setOrderFilter(prev => ({...prev, search: inputValue}))
    }, []);

    const handleToggleFilterClick = (state: boolean) => {
        setToggleFilters(state)
    }

    const handleApplyAllFilters = (filters: OrderFilter, filterCount: number) => {
        setOrderFilter({
            ...orderFilter,
            st: filters.st,
            cd: filters.cd,
            dr: filters.dr,
            cc: filters.cc === "" ? undefined : filters.cc,
            cbn: filters.cbn === "" ? undefined : filters.cbn,
            tgte: filters.tgte === "" ? undefined : filters.tgte,
            ic: filters.ic === "" ? undefined : filters.ic
        })
        setFilterCount(filterCount)
    }

    const handleResetAllFilters = () => {
        setOrderFilter({
            ...orderFilter,
            cd: moment().subtract(1, 'months').set({D: 1}).toDate().getTime(),
            st: [],
            dr: undefined,
            cc: undefined,
            cbn: undefined,
            tgte: undefined
        })
        setFilterCount(1)
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <OrderListPageHeader onChange={getInputValue} ordersCount={data.length} filterCount={filterCount}
                                     getToggleFiltersState={handleToggleFilterClick}/>
            </Paper>
            <Grid container height="84vh" p={1}>
                <Grid item>
                    <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%"}}>
                        <OrderFilterAccordeon onClickApplyAllFilters={handleApplyAllFilters}
                                              onClickResetAllFilters={handleResetAllFilters}/>
                    </Collapse>
                </Grid>
                {/*<Grid item height="inherit">*/}
                {/*    <Paper sx={{height: "100%", width: "100%"}}>*/}
                <DataGrid columns={columns}
                          columnVisibilityModel={{id: false, deliveryDate: false}}
                          rows={data}
                          paginationModel={paginationModel}
                          pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                          onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                          loading={fetching}
                          disableRowSelectionOnClick
                          density={density ? "compact" : "standard"}
                          slots={{
                              noRowsOverlay: () => (
                                  <Stack height="100%" alignItems="center" justifyContent="center">
                                      <Alert severity="info">
                                          {intl.formatMessage(getMessageDescriptor("orderListNoData"))}
                                      </Alert>
                                  </Stack>
                              )
                          }}
                          sx={{
                              "& .MuiDataGrid-cell:focus": {
                                  outline: "none"
                              },
                              "& .MuiDataGrid-columnHeader:focus": {
                                  outline: "none"
                              }
                          }}/>
                {/*</Paper>*/}
                {/*</Grid>*/}
            </Grid>
            <Dialog
                open={openDialogOrderComment}
                onClose={handleCloseDialogOrderComment}
                aria-describedby="alert-dialog-description-order-comments"
            >
                <DialogContent>
                    <OrderCommentsListStore orderId={orderId}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogOrderComment}><FormattedMessage
                        id={messages.genericClose.id}/></Button>
                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth="lg"
                open={idForDeletion !== null}
            >
                <DialogTitle>
                    <FormattedMessage id={messages.orderGridOrderDeletion.id}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationModalDeleteOrderConfirmation"))}</Alert>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <Alert
                            severity="info">{intl.formatMessage(getMessageDescriptor("orderCreationModalDeleteOrderCancel"))}</Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseDialogOrderDeletion}><FormattedMessage
                        id={messages.genericCancel.id}/></Button>
                    <Button variant="outlined" onClick={handleDeleteOrder}><FormattedMessage
                        id={messages.genericConfirm.id}/></Button>
                </DialogActions>
            </Dialog>
            {openModalErrorSubmit &&
                <Dialog
                    maxWidth="lg"
                    open={openModalErrorSubmit}
                >
                    <DialogTitle>
                        <FormattedMessage id={messages.orderListModalErrorSubmitInfoTitle.id}/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert
                                severity="warning">{intl.formatMessage(getMessageDescriptor("orderListModalErrorSubmitInfo"))}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DividingHR subContent/>
                    <DialogActions>
                        <Button variant="outlined" color="error"
                                onClick={() => {
                                    setOpenModalErrorSubmit(false)
                                    dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION})
                                }}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                        <Button variant="outlined"
                                onClick={() => handleActions(Actions.UPDATE, orderId)}><FormattedMessage
                            id={messages.orderGridLineActionUpdateOrder.id}/></Button>
                    </DialogActions>
                </Dialog>
            }
            {openModalSubmit &&
                <Dialog
                    maxWidth="lg"
                    open={openModalSubmit}
                >
                    <DialogTitle>
                        <FormattedMessage
                            id={orderSelected?.status === OrderStatus.AWAITING_VALIDATION ? messages.orderListModalReSubmitInfoTitle.id : messages.orderListModalSubmitInfoTitle.id}/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert
                                severity="warning">{
                                intl.formatMessage(getMessageDescriptor(orderSelected?.status === OrderStatus.AWAITING_VALIDATION ? "orderListModalReSubmitInfo" : "orderListModalSubmitInfo"), {
                                    br: <br/>
                                })
                            }
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DividingHR subContent/>
                    <DialogActions>
                        <Button variant="outlined" color="error"
                                onClick={() => {
                                    setOpenModalSubmit(false)
                                    dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION})
                                }}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                        <Button variant="outlined"
                                onClick={() => handleActions(Actions.SUBMIT_CONFIRM, orderId)}><FormattedMessage
                            id={messages.genericConfirm.id}/></Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}