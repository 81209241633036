import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../i18n/messages";
import {DividingHR} from "./DividingHR";
import {TextValueFieldBillingBox} from "./TextValueField";

interface InvoiceTotalProps {
    totalGrossTaxExcluded?: number
    totalShippingCost?: number
    shippingCostForced?: boolean
    discount?: number
    totalTaxExcluded?: number
    totalVat?: number
    totalWithTax?: number
    vatExcluded?: boolean
}

const InvoiceTotal = (props: InvoiceTotalProps) => {
    const {
        totalGrossTaxExcluded,
        totalShippingCost,
        discount,
        totalTaxExcluded,
        totalVat,
        totalWithTax,
    } = props
    const intl = useIntl()

    return <>
        <Grid container direction="column" py={1} px={5}>
            <Grid item>
                <Typography fontWeight="bolder" textAlign="center">
                    <FormattedMessage id={messages.invoiceTotalTitle.id}/>
                </Typography>
            </Grid>
            <DividingHR/>
            <Grid container item>
                <Grid item xs={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("invoiceTotalGrossExcludingTaxes"))}
                                value={totalGrossTaxExcluded || 0}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextValueFieldBillingBox
                                        style={{
                                            marginBottom: "0px",
                                            fontWeight: "bolder",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("invoiceTotalShippingCosts"))}
                                        value={totalShippingCost || 0}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("invoiceTotalDiscount"))}
                                value={discount || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={2} alignItems="center"
                      justifyContent={"center"}>
                    <DividingHR
                        subContent
                        style={{height: "60px", width: 1, margin: 0}}/>
                </Grid>
                <Grid item xs={5}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("invoiceTotalExcludingTaxes"))}
                                value={totalTaxExcluded || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={`${intl.formatMessage(getMessageDescriptor("invoiceTotalVat"))}`}
                                value={totalVat || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("invoiceTotalIncludingTaxes"))}
                                value={totalWithTax || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}
export default InvoiceTotal