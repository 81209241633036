import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {InvoiceLineGateway} from "../../../corelogic/gateways/InvoiceLineGateway";
import {InvoiceLine} from "corelogic/models/invoice";

export class ApiInvoiceLineGateway implements InvoiceLineGateway {
    retrieveByInvoiceId(invoiceId: number): Promise<InvoiceLine[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.invoiceLine, invoiceId.toString())})
    }
}