import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {InvoiceGateway} from "../../gateways/InvoiceGateway";
import {InvoiceFilter} from "../../models/invoice";
import {InvoiceLineGateway} from "../../gateways/InvoiceLineGateway";
import {PaginationFilter} from "../../models/item";

export const retrieveAllInvoicesFiltered = (filterValue: InvoiceFilter, pagination: PaginationFilter):
    ThunkResult<{ invoiceGateway: InvoiceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {invoiceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.invoices.INVOICES_RETRIEVING})
        try {
            const invoicesList = await invoiceGateway.retrieveAllFiltered(filterValue, pagination)
            dispatch({type: APP_ACTIONS_TYPES.invoices.INVOICES_RETRIEVED, payload: invoicesList})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSelectedInvoice = (id: number):
    ThunkResult<{ invoiceGateway: InvoiceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {invoiceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.invoices.INVOICE_FETCHING})
        try {
            const invoice = await invoiceGateway.retrieveById(id)
            dispatch({type: APP_ACTIONS_TYPES.invoices.INVOICE_SELECTING, payload: invoice})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

/** INVOICE LINES **/

export const retrieveInvoiceLinesByInvoiceId = (invoiceId: number):
    ThunkResult<{ invoiceLineGateway: InvoiceLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {invoiceLineGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.invoices.INVOICE_LINES_RETRIEVING})
        try {
            const invoiceLinesData = await invoiceLineGateway.retrieveByInvoiceId(invoiceId)
            dispatch({
                type: APP_ACTIONS_TYPES.invoices.INVOICE_LINES_RETRIEVED,
                payload: invoiceLinesData,
                target: invoiceId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}