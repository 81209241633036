import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    Menu,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {OrderStatus} from "../../../../../secondary/InMemory/Data/enums";
import Typography from "@mui/material/Typography";
import {AddressDisplayBlock, AddressForm} from "../../../Utils/Address";
import MenuItem from "@mui/material/MenuItem";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import {OrderStatusButton} from "../../../Utils/OrderStatusButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {Address, Customer} from "../../../../../../corelogic/models/customer";
import {addCustomerDeliveryAddress} from "../../../../../../corelogic/usecase/customer/customersActions";
import {useDispatch, useSelector} from "react-redux";
import {Order, OrderInfoDialog} from "../../../../../../corelogic/models/order";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import moment, {Moment} from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReturnButton from "../../../Utils/ReturnButton";
import {TextValueField} from "../../../Utils/TextValueField";
import EditIcon from '@mui/icons-material/Edit';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarningIcon from '@mui/icons-material/Warning';
import Badge from '@mui/material/Badge';
import {getOrderLinesSelector} from "../../../../../../store/selectors/ordersSelectors";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export interface FormError {
    deliveryAddress: boolean,
    delayRequested: boolean,
    emptyLines: boolean
}

export interface OrderCreationPageHeaderProps {
    customerSelected: Customer
    addresses?: Address[]
    orderSelected?: Order
    formData: Order
    isSmallerThanXLDeviceWidth?: boolean
    onChangeDisplayButtonClick?: () => void
    onValidate: (form: OrderInfoDialog) => void
    isCustomer?: boolean,
}

export const OrderCreationPageHeader = (props: OrderCreationPageHeaderProps) => {
    const {
        customerSelected,
        addresses,
        orderSelected,
        formData,
        isSmallerThanXLDeviceWidth,
        onChangeDisplayButtonClick,
        onValidate,
        isCustomer
    } = props
    const dispatch = useDispatch()
    const intl = useIntl()
    const {dataOrderLines, fetchingOrderLines} = useSelector(getOrderLinesSelector)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [openModalAddress, setOpenModalAddress] = useState(false)
    const [openModalInfoOrder, setOpenModalInfoOrder] = useState(false)
    const [fullWidth, setFullWidth] = useState(true)
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('md')
    const [isShoppingDisplay, setIsShoppingDisplay] = useState(false)
    const [deliveryAddressToDisplay, setDeliveryAddressToDisplay] = useState(
        {
            title: formData.deliveryAddressBusinessName || customerSelected.businessName || "",
            businessName: formData.deliveryAddressBusinessName || customerSelected.businessName || "",
            address1: formData.deliveryAddress1 || customerSelected.address1 || "",
            address2: formData.deliveryAddress2 || customerSelected.address2 || "",
            address3: formData.deliveryAddress3 || customerSelected.address3 || "",
            zipCode1: formData.deliveryAddressZipCode1 || customerSelected.zipCode1 || "",
            zipCode2: formData.deliveryAddressZipCode2 || customerSelected.zipCode2 || "",
            city: formData.deliveryAddressCity || customerSelected.city || "",
            country: formData.deliveryAddressCountry || customerSelected.country || null
        }
    )
    const [delayRequestedValue, setDelayRequestedValue] = useState<Moment | null>(moment(formData.delayRequested))
    const [form, setForm] = useState<OrderInfoDialog>({
        reference: formData.reference ? formData.reference : orderSelected?.reference || "",
        delayRequested: delayRequestedValue,
        deliveryAddress: deliveryAddressToDisplay,
        comment: formData.comment ? formData.comment : orderSelected?.comment || ""
    })
    const [formError, setFormError] = useState<FormError>({
        delayRequested: false,
        deliveryAddress: false,
        emptyLines: false
    })

    useEffect(() => {
        setFormError({
            deliveryAddress: !deliveryAddressToDisplay.businessName || !deliveryAddressToDisplay.address1 || !deliveryAddressToDisplay.zipCode1 || !deliveryAddressToDisplay.city,
            delayRequested: delayRequestedValue === null || delayRequestedValue < moment().add(1, "day").startOf("day"),
            emptyLines: false
        })
    }, [delayRequestedValue, deliveryAddressToDisplay])

    const handleConfirmAddressCreation = (address: Address) => {
        setOpenModalAddress(false)
        if (address) {
            dispatch(addCustomerDeliveryAddress(customerSelected.id, address))
            setDeliveryAddressToDisplay({
                ...deliveryAddressToDisplay,
                title: address.title,
                businessName: address.businessName || "",
                address1: address.address1 || "",
                address2: address.address2 || "",

                address3: address.address3 || "",
                zipCode1: address.zipCode1 || "",
                zipCode2: address.zipCode2 || "",
                city: address.city || "",
                country: address.country || null
            })
        }
    }

    const handleMenuItemClick = (addressId?: number, isDefaultAddress?: boolean) => {
        const selectedAddress = customerSelected.deliveryAddresses.find(x => x.id === addressId)
        const defaultAdress = {
            title: customerSelected.businessName || "",
            businessName: customerSelected.businessName || "",
            address1: customerSelected.address1 || "",
            address2: customerSelected.address2 || "",
            address3: customerSelected.address3 || "",
            zipCode1: customerSelected.zipCode1 || "",
            zipCode2: customerSelected.zipCode2 || "",
            city: customerSelected.city || "",
            country: customerSelected.country || null
        }
        if (selectedAddress) {
            setDeliveryAddressToDisplay({
                title: selectedAddress.title || "",
                businessName: selectedAddress.businessName || "",
                address1: selectedAddress.address1 || "",
                address2: selectedAddress.address2 || "",
                address3: selectedAddress.address3 || "",
                zipCode1: selectedAddress.zipCode1 || "",
                zipCode2: selectedAddress.zipCode2 || "",
                city: selectedAddress.city || "",
                country: selectedAddress.country || null
            })
            setForm({
                ...form,
                deliveryAddress: selectedAddress
            })
        }
        if (isDefaultAddress) {
            setDeliveryAddressToDisplay({
                title: customerSelected.businessName || "",
                businessName: customerSelected.businessName || "",
                address1: customerSelected.address1 || "",
                address2: customerSelected.address2 || "",
                address3: customerSelected.address3 || "",
                zipCode1: customerSelected.zipCode1 || "",
                zipCode2: customerSelected.zipCode2 || "",
                city: customerSelected.city || "",
                country: customerSelected.country || null
            })
            setForm({
                ...form,
                deliveryAddress: defaultAdress
            })
        }
        handleMenuClose()
    }

    const handleChangeDate = (newValue: Moment | null) => {
        setDelayRequestedValue(newValue)
        if (newValue) {
            setForm({...form, delayRequested: newValue})
        }
    }

    const handleChangeReference = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const input = e.target.value
        if (input) {
            setForm({...form, reference: input})
        }
    }

    const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const input = e.target.value
        if (input) {
            setForm({...form, comment: input})
        }
    }

    const handleValidation = () => {
        onValidate(form)
        setOpenModalInfoOrder(false)
    }

    const handleCancel = () => {
        setDelayRequestedValue(moment(formData.delayRequested ? formData.delayRequested : orderSelected?.delayRequested))
        setForm({
            ...form,
            delayRequested: delayRequestedValue,
            comment: formData.comment ? formData.comment : orderSelected?.comment || "",
            reference: formData.reference ? formData.reference : orderSelected?.reference || ""
        })
        setOpenModalInfoOrder(false)
    }

    const handleOpenAddressModal = () => {
        handleMenuClose()
        setOpenModalAddress(true)
    }

    const handleCancelAddressCreation = () => {
        setOpenModalAddress(false)
    }

    const handleOpenModalInfoOrder = () => {
        setOpenModalInfoOrder(true)
    }

    const handleChangeDisplayResponsive = () => {
        setIsShoppingDisplay(prevState => !prevState)
        if (onChangeDisplayButtonClick) {
            onChangeDisplayButtonClick()
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextValueField style={{fontWeight: "bolder"}}
                                                    text={intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderTitle"))}
                                                    value={orderSelected?.sodiwinNumOrder ? orderSelected.sodiwinNumOrder : orderSelected?.numOrder ? orderSelected.numOrder : ""}/>
                                </Grid>
                                {!isCustomer && <Grid item>
                                    <TextValueField style={{fontWeight: "bolder"}}
                                                    text={intl.formatMessage(getMessageDescriptor("orderGridColCodeClient"))}
                                                    value={orderSelected?.orderedCustomer?.code || ""}/>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <OrderStatusButton
                                status={orderSelected?.status ? orderSelected.status : OrderStatus.CREATION}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("genericEditModeOrder"))}>
                                <Button variant="contained" onClick={handleOpenModalInfoOrder}
                                        color={formError.deliveryAddress || formError.delayRequested ? "warning" : "primary"}>
                                    {formError.deliveryAddress || formError.delayRequested ?
                                        <>
                                            <WarningIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                            <EditIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                        </>
                                        :
                                        <EditIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                    }
                                    <Typography>infos</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                        {isSmallerThanXLDeviceWidth &&
                            <>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item>
                                    {!isShoppingDisplay ?
                                        <Tooltip
                                            title={intl.formatMessage(getMessageDescriptor("genericViewBillingDetails"))}>
                                            <Button variant="contained" onClick={handleChangeDisplayResponsive}>
                                                <Badge badgeContent={dataOrderLines.length} color="error">
                                                    <ShoppingCartIcon
                                                        sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Badge>
                                            </Button>
                                        </Tooltip>
                                        :
                                        <Tooltip
                                            title={intl.formatMessage(getMessageDescriptor("genericViewItemList"))}>
                                            <Button variant="contained" onClick={handleChangeDisplayResponsive}>
                                                <Badge badgeContent={dataOrderLines.length} color="error">
                                                    <FormatListBulletedIcon
                                                        sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Badge>
                                            </Button>
                                        </Tooltip>
                                    }
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {openModalInfoOrder &&
                <Dialog
                    open
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    keepMounted
                >
                    <DialogTitle sx={{textAlign: "center"}}>
                        {intl.formatMessage(getMessageDescriptor("orderInformations"))}
                    </DialogTitle>
                    <DividingHR/>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Paper sx={{p: 2}}>
                                    <TextField variant="standard"
                                               type="text"
                                               fullWidth
                                               label={intl.formatMessage(getMessageDescriptor("orderReference"))}
                                               placeholder={intl.formatMessage(getMessageDescriptor("orderReference"))}
                                               defaultValue={formData.reference ? formData.reference : orderSelected?.reference || ""}
                                               onChange={handleChangeReference}/>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper sx={{p: 2}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Box sx={{display: "flex", alignItems: "center", margin: "10px"}}>
                                            <DesktopDatePicker
                                                label={intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDeliveryDelay"))}
                                                inputFormat="DD/MM/YYYY"
                                                value={delayRequestedValue}
                                                disablePast
                                                minDate={moment().add(customerSelected.transitTime || 1, "day")}
                                                onChange={handleChangeDate}
                                                onError={() => setFormError({...formError, delayRequested: true})}
                                                renderInput={(params) => <TextField fullWidth {...params}
                                                                                    variant="standard"
                                                />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                    {formError.delayRequested && <Grid item container py={1}>
                                        <Alert
                                            severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationPageWarningDeliveryDate"))}</Alert>
                                    </Grid>}
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper sx={{p: 1}}>
                                    <Grid container alignItems="center" justifyContent="space-between" px={2}>
                                        <Grid item>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <LocationOnIcon/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>
                                                        <FormattedMessage
                                                            id={messages.orderCreationPageHeaderDeliveryAddressButton.id}/>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <AddressDisplayBlock hasFocus={true}
                                                                         title={deliveryAddressToDisplay?.title}
                                                                         businessName={deliveryAddressToDisplay?.businessName}
                                                                         address1={deliveryAddressToDisplay?.address1}
                                                                         address2={deliveryAddressToDisplay?.address2}
                                                                         address3={deliveryAddressToDisplay?.address3}
                                                                         zipCode1={deliveryAddressToDisplay?.zipCode1}
                                                                         zipCode2={deliveryAddressToDisplay?.zipCode2}
                                                                         city={deliveryAddressToDisplay?.city}
                                                                         country={deliveryAddressToDisplay?.country?.label}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDeliveryAddressButton"))}>
                                                <Button id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        variant="contained">
                                                    <EditLocationIcon sx={{fill: "white"}}/>
                                                </Button>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleMenuClose}>
                                                <Grid container alignItems="center" justifyContent="center">
                                                    <Grid item>
                                                        <LocationOnIcon/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>
                                                            {intl.formatMessage(getMessageDescriptor("customerPageDeliveryAddressTitle"))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <DividingHR/>
                                                <MenuItem sx={{display: "flex", justifyContent: "center"}}
                                                          onClick={handleOpenAddressModal}>
                                                    <Button variant="contained">
                                                        <AddLocationAltIcon sx={{marginRight: "5px"}}/>
                                                        <Typography>
                                                            {intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDeliveryAddressCreation"))}
                                                        </Typography>
                                                    </Button>
                                                </MenuItem>
                                                <DividingHR subContent/>
                                                <MenuItem onClick={() => handleMenuItemClick(undefined, true)}>
                                                    <Paper sx={{width: "100%", padding: "10px"}}>
                                                        <AddressDisplayBlock
                                                            hasFocus={deliveryAddressToDisplay.businessName === customerSelected.businessName && deliveryAddressToDisplay.address1 === customerSelected.address1}
                                                            title={customerSelected.businessName}
                                                            businessName={customerSelected.businessName}
                                                            address1={customerSelected.address1}
                                                            address2={customerSelected.address2}
                                                            address3={customerSelected.address3}
                                                            zipCode1={customerSelected.zipCode1}
                                                            zipCode2={customerSelected.zipCode2}
                                                            city={customerSelected.city}
                                                            country={customerSelected.country?.label}/>
                                                    </Paper>
                                                </MenuItem>
                                                <DividingHR subContent/>
                                                {addresses && addresses.length > 0 && addresses.map(x =>
                                                    <MenuItem onClick={() => handleMenuItemClick(x.id)}
                                                              key={x.id}>
                                                        <Paper sx={{width: "100%", padding: "10px"}}>
                                                            <AddressDisplayBlock
                                                                hasFocus={deliveryAddressToDisplay.businessName === x.businessName && deliveryAddressToDisplay.address1 === x.address1}
                                                                title={x.title}
                                                                businessName={x.businessName}
                                                                address1={x.address1}
                                                                address2={x.address2}
                                                                address3={x.address3}
                                                                zipCode1={x.zipCode1}
                                                                zipCode2={x.zipCode2}
                                                                city={x.city}
                                                                country={x.country?.label}/>
                                                        </Paper>
                                                    </MenuItem>)}
                                            </Menu>
                                        </Grid>
                                        {formError.deliveryAddress && <Grid item container py={1}>
                                            <Alert
                                                severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationPageWarningDeliveryAddress"))}</Alert>
                                        </Grid>}
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper sx={{p: 2}}>
                                    <TextField
                                        variant="standard"
                                        defaultValue={formData.comment ? formData.comment : orderSelected?.comment || ""}
                                        label={intl.formatMessage(getMessageDescriptor("orderCommentInputLabel")) + "..."}
                                        placeholder={intl.formatMessage(getMessageDescriptor("orderCommentInputLabel")) + "..."}
                                        onChange={handleChangeComment}
                                        multiline
                                        size="medium"
                                        maxRows={4}
                                        minRows={3}
                                        fullWidth
                                        type="text"
                                    />
                                </Paper>
                            </Grid>
                            <Grid item container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="error" onClick={handleCancel}>
                                        {intl.formatMessage(getMessageDescriptor("genericCancel"))}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleValidation}
                                            disabled={formError.deliveryAddress || formError.delayRequested}>
                                        {intl.formatMessage(getMessageDescriptor("genericValidate"))}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            {openModalAddress &&
                <Dialog
                    open
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                >
                    <DialogTitle sx={{textAlign: "center"}}>
                        {intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDeliveryAddressCreation"))}
                        <DividingHR/>
                    </DialogTitle>
                    <DialogContent>
                        <AddressForm onCancelClick={handleCancelAddressCreation}
                                     onConfirmClick={handleConfirmAddressCreation}
                                     data={{
                                         title: "",
                                         businessName: "",
                                         address1: "",
                                         address2: "",
                                         address3: "",
                                         zipCode1: "",
                                         zipCode2: "",
                                         city: ""
                                     }}
                        />
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}